import React from 'react'
// import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import BackgroundImage from 'gatsby-background-image'
import { navigate } from 'gatsby'

import Layout from '../components/layout'

import { styles } from '../styles/styles.jsx'

const Portfolio = ({ data }) => (
  <Layout>
    <BackgroundImage
      className=""
      fluid={data.datoCmsPortfolio.featured.image.fluid}
    >
      <div className={styles.featuredBgOverlay} />
      <div className={styles.container}>
        <div className={styles.featuredContainer}>
          <h2 className={styles.featuredTag}>Featured Case Study</h2>
          <h1 className={styles.featuredTitle}>
            {data.datoCmsPortfolio.featured.title}
          </h1>
          {/* <p className={styles.featuredDescription}>
            {data.datoCmsPortfolio.featured.description}
          </p> */}
          <span
            className={styles.featuredLink}
            style={{ borderWidth: '1px', cursor: 'pointer', maxWidth: '100px', textAlign: 'center' }}
            onClick={() =>
              navigate(`/portfolio/${data.datoCmsPortfolio.featured.slug}`)
            }
          >
            View More
          </span>
        </div>
      </div>
    </BackgroundImage>
    <div className={styles.container}>
      <div className={styles.workContainer}>
        {data.allDatoCmsCaseStudy.edges.map(({ node: item }) => (
          <div
          className={styles.workItemContainer}
            key={item.id}
            style={{ boxSizing: 'border-box' }}
          >
            <div className={styles.workItem}>
              <BackgroundImage
                fluid={item.image.fluid}
                className={styles.workImage + ' ' + styles.workImageBg}
              />
              <div className={styles.workContent}>
                <p className={styles.workTag}>Case Study</p>
                <h2 className={styles.workItemTitle}>{item.title}</h2>
                <p className={styles.workDescription}>{item.description}</p>
                <Link className={styles.workLink} to={'/portfolio/' + item.slug}>
                  View More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export default Portfolio

export const portfolioListQuery = graphql`
  query portfolioListQuery {
    allDatoCmsCaseStudy(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          slug
          title
          description
          publishDate
          image {
            fluid(maxWidth: 500) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    datoCmsPortfolio {
      featured {
        slug
        title
        description
        publishDate
        image {
          fluid(maxWidth: 500) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`
